import React, { useEffect, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector } from "@xstate/react";
import {
  AuthenticationMachineContext,
  unauthenticatedSelector,
  accountSelector,
  errorSelector,
  invalidTenantSelector,
  validTenantSelector,
} from "../auth/authContext";
import { useHistory } from "react-router";
import { InteractionStatus } from "@azure/msal-browser";
import { SpinnerGap } from "phosphor-react";

const Authenticating: React.FC = () => {
  const { accounts, inProgress } = useMsal();
  const authenticationMachine = useContext(AuthenticationMachineContext);
  // @ts-ignore
  const account = useSelector(authenticationMachine as any, accountSelector);
  const unauthenticated = useSelector(authenticationMachine as any, unauthenticatedSelector);
  const invalidTenant = useSelector(authenticationMachine as any, invalidTenantSelector);
  const validTenant = useSelector(authenticationMachine as any, validTenantSelector);
  const error = useSelector(authenticationMachine as any, errorSelector);
  const history = useHistory();

  useEffect(() => {
    if (account && validTenant) {
      const redirectUrl = sessionStorage.getItem("redirect");
      if (redirectUrl) {
        sessionStorage.removeItem("redirect");
        history.push(redirectUrl);
      } else {
        history.push("app/run-job");
      }
    }
  }, [account, history, validTenant]);

  useEffect(() => {
    if (unauthenticated && accounts.length === 0 && (inProgress === InteractionStatus.None || (inProgress === InteractionStatus.Startup && error))) {
      history.push("login");
    }
  }, [accounts.length, error, history, inProgress, unauthenticated]);

  useEffect(() => {
    if (invalidTenant) {
      history.push("403");
    }
  }, [history, invalidTenant]);

  return (
    <div className="w-full h-screen flex justify-center items-center ">
      <div className="flex items-center">
        <SpinnerGap size={36} className="animate-spin" /> <span className="ml-4">Authenticating...</span>
      </div>
    </div>
  );
};

export default Authenticating;
