// Theirs ---------------------------------------------------------------------
import React, { Fragment, useContext } from "react";
import { NavLink } from "react-router-dom";
// Ours ---------------------------------------------------------------------
import { Transition, Disclosure, Menu } from "@headlessui/react";
import { BellIcon, XIcon, MenuIcon } from "@heroicons/react/outline";
import { useMsal } from "@azure/msal-react";
import { AuthenticationMachineContext } from "../auth/authContext";
import { useSelector } from "@xstate/react";
import BuildInfo from "../components/meta/BuildInfo";
import ReactTooltip from "react-tooltip";
import "../../node_modules/react-tooltip/dist";
import "react-datepicker/dist/react-datepicker.css";
import Banners from "../components/banners/Banners";

const accountSelector = (state: any) => state.context.account;

// Types ----------------------------------------------------------------------
export const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];
export const navigation = [
  { name: "Run", path: "/app/run-job", current: false, title: "Run" },
  { name: "Jobs", path: "/app/jobs", current: false, title: "Jobs" },
  { name: "Settings", path: "/app/settings/connections", current: false, title: "Settings" },
];

// Local ----------------------------------------------------------------------

/**
 * Set theme styles based on the app environment.
 *
 * For example:
 * > prod = pregis.syncfish.app
 * > prodtest = pregistest.syncfish.app
 * > dev = localhost or syncfish.com.au
 */
function setInstanceStyles(url: string) {
  const lowerCaseUrl = url.toLowerCase();
  const isProductionTest = lowerCaseUrl.endsWith("test.syncfish.app");
  const isDevelopment = lowerCaseUrl === "localhost" || lowerCaseUrl.endsWith("syncfish.com.au");

  let bgStyle = "bg-gray-50";
  let environmentType = "";
  let bannerStyles = "";
  let badgeStyles = "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ";

  if (isDevelopment) {
    environmentType = "DEV";
    bannerStyles = "from-blue-500 via-green-500 to-purple-500";
    badgeStyles += "bg-blue-100 text-blue-800";
    bgStyle = "bg-blue-50";
  }

  if (isProductionTest) {
    environmentType = "TEST";
    bannerStyles = "from-yellow-500 via-orange-500 to-red-500";
    badgeStyles += "bg-yellow-100 text-yellow-800";
    bgStyle = "bg-yellow-50";
  }

  return {
    environmentType,
    bannerStyles,
    badgeStyles,
    bgStyle,
  };
}

// Component ----------------------------------------------------------------------
const Layout: React.FC = ({ children }) => {
  const { instance } = useMsal();
  const { environmentType, bannerStyles, badgeStyles, bgStyle } = setInstanceStyles(window.location.hostname);
  const authenticationMachine = useContext(AuthenticationMachineContext);
  const account = useSelector(authenticationMachine as any, accountSelector);

  return (
    <div className={`${bgStyle} min-h-screen pb-52 pt-14`}>
      {bannerStyles && <div className={`z-20 fixed top-0 left-0 right-0 h-1 bg-gradient-to-r ${bannerStyles}`} />}
      <Disclosure as="nav" className={`fixed left-0 right-0 z-10 bg-white shadow-md ${bannerStyles ? "top-1" : "top-0"}`}>
        {({ open }) => (
          <>
            <div className="container px-4 mx-auto sm:px-6 lg:px-8">
              <div className="flex justify-between h-14">
                <div className="flex">
                  <div className="flex items-center flex-shrink-0">
                    <NavLink to="/app/run-job" className="flex items-center -mt-1 font-bold text-left text-gray-800 text-md dark:text-gray-200" href="#">
                      <img src="/syncfish-logo.png" alt="Syncfish logo" className="w-1/5 mr-2" />
                      <div>
                        CI Synchronizer <div className="-mt-1 text-xs font-normal">Enterprise Edition</div>
                      </div>
                    </NavLink>
                  </div>

                  <div className="hidden sm:ml-6 sm:flex sm:space-x-5 sm:pl-5">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.path}
                        className="inline-flex items-center px-5 pt-1 text-sm font-medium text-gray-500 border-b-2 border-transparent hover:text-gray-700 hover:border-gray-300 whitespace-nowrap"
                        activeClassName="border-primary-500 text-gray-900 border-b-2"
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                  {environmentType && (
                    <div className="flex items-center mx-4 ">
                      <span className={badgeStyles}>{environmentType}</span>
                    </div>
                  )}
                </div>

                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  <Menu as="div" className="relative ml-3">
                    {({ open: menuOpen }) => (
                      <>
                        <div className="flex items-center">
                          <Menu.Button className="flex text-sm bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                            <span className="sr-only">Open user menu</span>
                            <div className="flex flex-col text-sm text-right">
                              <span>{account?.name}</span>
                              <span className="text-xs text-gray-500">{account?.username}</span>
                            </div>
                          </Menu.Button>
                        </div>
                        <Transition
                          show={menuOpen}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            <Menu.Item>
                              <button
                                type="button"
                                className="block px-4 py-2 text-sm text-gray-700"
                                onClick={() =>
                                  instance.logoutRedirect({
                                    account,
                                  })
                                }
                              >
                                Log out
                              </button>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
                <div className="flex items-center -mr-2 sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? <XIcon className="block w-6 h-6" aria-hidden="true" /> : <MenuIcon className="block w-6 h-6" aria-hidden="true" />}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.path}
                    className="block py-2 pl-3 pr-4 text-base font-medium text-gray-600 border-l-4 border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800"
                    activeClassName="bg-primary-50 border-primary-500 text-primary-700"
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-200">
                <div className="flex items-center px-4">
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">{account?.name}</div>
                    <div className="text-sm font-medium text-gray-500">{account?.username}</div>
                  </div>
                  <button
                    type="button"
                    className="flex-shrink-0 p-1 ml-auto text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="w-6 h-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 space-y-1">
                  {userNavigation.map((item) => (
                    <a key={item.name} href={item.href} className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100">
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <main id="main-layout" className="container p-1 mx-auto my-4 sm:p-0">
        <div className="relative pt-3 mx-auto sm:px-6 lg:px-8">
          <Banners />
          <div className="mb-5 text-gray-500">
            <BuildInfo />
          </div>
          {children}
        </div>
        <ReactTooltip />
      </main>
    </div>
  );
};

export default Layout;
