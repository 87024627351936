// Theirs ---------------------------------------------------------------------
import React from "react";
import { useMachine } from "@xstate/react";
import bannersMachine from "../../machines/bannersMachine";
import protectedApi from "../../auth/protectedApi";
import Banner from "./Banner";
// Ours -----------------------------------------------------------------------

// Types ----------------------------------------------------------------------
// Local ----------------------------------------------------------------------
async function getBanners() {
  const { data } = await protectedApi.get("banners");

  return {
    banners: data.banners,
  };
}

// Component ------------------------------------------------------------------
const Banners: React.FC = () => {
  const [state] = useMachine(bannersMachine, {
    services: {
      fetchBanners: async (_context, _event) => getBanners(),
    },
  });

  const { banners } = state.context;

  if (banners.length === 0) {
    return <></>;
  }

  return (
    <div className="pointer-events-none">
      {banners.map((banner, index) => {
        if (banner.type === "EXPIRING_LICENSE") {
          return (
            <Banner key={`banner-${index}-${banner.type}`} type="warning">
              {banner.data.licenseType === "TRIAL" ? "Your trial license will expire on " : "Your license will expire on "}
              {new Date(banner.data.licenseEndDate).toLocaleDateString()}
            </Banner>
          );
        }

        if (banner.type === "EXPIRED_LICENSE") {
          return (
            <Banner key={`banner-${index}-${banner.type}`} type="error">
              {banner.data.licenseType === "TRIAL" ? "Your trial license expired on " : "Your license expired on "}
              {new Date(banner.data.licenseEndDate).toLocaleDateString()}
            </Banner>
          );
        }

        return <></>;
      })}
    </div>
  );
};

export default Banners;
