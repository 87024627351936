import { assign, createMachine, StateMachine } from "xstate";

type ExpiredLicenseBanner = {
  type: "EXPIRED_LICENSE";
  data: {
    licenseType: "TRIAL" | "PRODUCTION";
    licenseEndDate: string;
  };
};

type ExpiringLicenseBanner = {
  type: "EXPIRING_LICENSE";
  data: {
    licenseType: "TRIAL" | "PRODUCTION";
    licenseEndDate: string;
  };
};

type Banner = ExpiredLicenseBanner | ExpiringLicenseBanner;

export interface BannersMachineContext {
  banners: Banner[];
}

const bannersMachine = createMachine<BannersMachineContext>(
  {
    id: "fetch-banners",
    initial: "loading",
    context: {
      banners: [],
    },
    states: {
      idle: {
        on: {
          FETCH: "loading",
        },
      },
      loading: {
        invoke: {
          src: "fetchBanners",
          onDone: {
            target: "success",
            actions: ["updateBanners"],
          },
          onError: {
            target: "failure",
            actions: assign({
              banners: (_context, _event) => [],
            }),
          },
        },
      },
      success: {
        on: { FETCH: "loading" },
      },
      failure: {
        on: {
          FETCH: "loading",
        },
      },
    },
  },
  {
    actions: {
      updateBanners: assign({
        banners: (_, event) => event.data.banners,
      }),
    },
  },
);

export default bannersMachine;
