import axios, { AxiosError } from "axios";
import { msalInstance } from "../index";
import { browserHistory } from "../appinsights";
import { loginRequest } from "./authConfig";
const protectedApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

protectedApi.interceptors.request.use(
  async (config) => {
    const currentAccount = msalInstance.getActiveAccount();
    if (currentAccount && currentAccount.idTokenClaims) {
      try {
        const token = await msalInstance.acquireTokenSilent({
          scopes: [`api://${process.env.REACT_APP_AUTH_SF_APP_REG_CLIENT_ID}/access_as_user`],
        });

        if (token) {
          config.headers = {
            Authorization: `Bearer ${token.accessToken}`,
          };
        }
      } catch {
        sessionStorage.setItem("redirect", window.location.pathname);
        msalInstance.loginRedirect(loginRequest);
      }
    }

    return config;
  },
  (error) => Promise.reject(error),
);

protectedApi.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response) {
      if (error.response.status === 403) {
        browserHistory.push("/app/403");
      }

      if (error.response.status === 401) {
        browserHistory.push("/app/401");
      }

      if (error.response.status === 503) {
        return Promise.reject(
          new Error(`503 Error: Our services aren't available right now. We're working to restore all services as soon as possible. Please check back soon.`),
        );
      }
    }

    return Promise.reject(new Error(error.response?.data || error.message));
  },
);

let cachedMaintenanceMode: { maintenance: boolean } | null = null;
let lastTimeChecked = 0;

// Maintenance mode?
protectedApi.interceptors.request.use(
  async (config) => {
    // If we have a cached value, and it's less than 5 seconds old, use it
    if (cachedMaintenanceMode && lastTimeChecked > Date.now() - 5 * 1000) {
      // If previously maintenance mode, push to maintenance mode page, with error
      if (cachedMaintenanceMode.maintenance === true) {
        browserHistory.push("/maintenance");

        throw new Error("Maintenance mode");
      }

      return config;
    }

    // Set the lastTimeChecked before we make the request to help prevent multiple requests
    lastTimeChecked = Date.now();

    const result = await axios.get("/maintenance.json");

    cachedMaintenanceMode = result.data;

    if (result.data.maintenance === true) {
      browserHistory.push("/maintenance");

      throw new Error("Maintenance mode");
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export default protectedApi;
